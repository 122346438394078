import React from "react"

/*** COMPONENTS ***/
import Main from "../../components/main"
import SEO from "../../components/seo"
import "../../styles/project.css"
import imgHeader from "../../images/die-by-dye.png"

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes"

function openGithubDieByDye() {
  window.open(
    "https://github.com/johannagranstroem/TDDD23-Design-and-Programming-of-Computer-Games",
    "_blank"
  )
}

const diebydyePage = () => (
  <Main>
    <SEO title="Die by Dye" />
    <div class="project-container">
      <div class="project-grid-item-11">
        <img alt="Header-DieByBye" src={imgHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">DIE BY DYE</p>
          <p class="project-header-text-grey">GAME PROGAMMING </p>
        </div>
      </div>
      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">Awards</p>
        <p class="project-bulletpoint-text"> &#9652; Liu Game Awards</p>
        <p class="project-bulletpoint-text">Language</p>
        <p class="project-bulletpoint-text"> &#9642; C#</p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Completed 2019-10-30</p>
      </div>
      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          The game Die by Dye was a project created by my associate and me. We
          wanted to come up with our idea and design for our game. The game
          itself is a 2D topdown single-player game where the purpose is to stay
          alive and get the highest score. You do this by killing the spawning
          enemies. To do that, the player has to match the colour of the weapon
          with the colour of the enemies. This game was one of the nominees to
          Liu Game Awards and won third place.
          <br />
          <br />
          You can checkout the project on{" "}
          <button
            class="project-text-link-style"
            onClick={() => openGithubDieByDye()}
          >
            Github
          </button>
          !
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects" href={ROUTES.PROJECTS}>
          &#8592; Back to projects
        </a>
      </div>
    </div>
  </Main>
)

export default diebydyePage
